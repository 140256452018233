<template>
  <div class="content-app" id="case-kinds">
    <div class="frame-case-content" >
      <div class="case-title">各行业案例</div>
      <div class="case-item">我们提供个性化服务，为各行各业提供优质服务</div>
      <div class="case-kinds">
        <template v-for="item in items" :key="item.id">
          <div
            class="frame-case-kinds"
            @click="selectKind(item.id)"
            :class="{  'selected': item.id === currentIndex,
									'selected-blue': item.id === currentIndex  }">
            {{ item.name }}
          </div>
        </template>
      </div>
      <div class="case-content">
        <template v-for="item in cases" :key="item.id">
          <div class="case-content-row">
            <img :src="item.image" class="case-size">
            <div class="case-item-title">{{ item.name }}</div>
            <div class="case-item-content">{{ item.description }}</div>
          </div>
        </template>
      </div>
      <div class="case-bottom">持续更新中......</div>
    </div>
    <ContentBottom></ContentBottom>
  </div>
</template>
<script>
import ContentBottom from './contentBottom.vue';
import { getCompleteImageUrl } from '../imgurl.js';


export default {
  components:{
    ContentBottom
  },
  data(){
    return{
      cases:[],
      items: [],
    }
  },
  created() {
    // 从路由查询参数中获取 currentIndex
    this.currentIndex = Number(this.$route.query.id);
    console.log("currentIndex:", this.currentIndex);
    // 获取轮播图列表，并选中相应的项
    this.sele();
    // this.sel()
  },
  methods: {
    sele() {
      // this.$axios.get('/carousel/getAll')
      this.$axios.get('/case.caseType/getAll')
        .then((res) => {
          console.log(111)
          this.items = res.data.data;
          console.log(this.items);
          // 如果 currentIndex 存在，则设置默认选中项并加载数据
          if (this.currentIndex) {
            this.selectKind(this.currentIndex);
          } else {
            this.selectKind(0)
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
    selectKind(id) {
      console.log("点击的 ID 是:", id);
      this.lbid = id; // 设置当前选中的 ID
      this.sel(); // 调用 sel 方法查询数据
      // 更新 currentIndex 以便高亮显示
      this.currentIndex = id;
    },
    // 根据 ID 查询案例信息
    sel() {
      // this.$axios.get('/case/selBya?id=' + this.lbid)
      this.$axios.get('/case.cases/getCases?case_type_id=' + this.lbid)
        .then((res) => {
          console.log(res)
          // this.cases = res.data.map(item => ({
          //   ...item,
          //   caseImage: getCompleteImageUrl(item.caseImage)
          // }));
          this.cases = res.data.data
          console.log(this.cases);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // 根据 ID 查询案例信息
    // sel() {
    //     this.$axios.get('/case/getAll').then((res) => {
    //     this.cases = res.data.map(item => ({...item,caseImage: getCompleteImageUrl(item.caseImage)}));
    //     console.log(this.cases);
    //     })
    //     .catch((error) => {
    //         console.error('Error fetching data:', error);
    //     });
    // }
  }

}
</script>
<style scoped>
.content-app{
  background: radial-gradient(60% 50% at 80% 50%,rgb(176, 167, 234),transparent),
  radial-gradient(100% 40% at left 40%,rgb(160, 228, 193),transparent),
  radial-gradient(60% 50% at left 60%,rgb(176, 167, 234),transparent),
  radial-gradient(80% 50% at right bottom,rgb(160, 228, 193),transparent);
  /* background: linear-gradient(
      7deg,
      rgba(115, 224, 169, 1),
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(344deg, rgba(77, 0, 255, 1), rgba(0, 0, 255, 0) 50.71%); */
}
.frame-case-content{
  /* height: 1222px; */
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  padding: 25px 15%;
}
.case-title{
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 49.39px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
}
.case-item{
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.58px;
  color: rgba(56, 56, 56, 1);
  text-align: center;
  margin: 10px 0px;
  margin-bottom: 20px;
}
.case-kinds{
  display: flex;
  /* margin-left: 4%; */
}
.frame-case-kinds{
  width: 100px;
  margin: 10px 30px;
  border-radius: 4px;
  /* background: rgba(235, 242, 255, 1); */
  font-size: 18px;
  line-height: 30px;
  color: rgba(56, 56, 56, 1);
  text-align: center;
}
.frame-case-kinds.selected-blue {
  color: skyblue; /* 或者其他你希望的样式 */
}
.case-content{
  display: flex;
  flex-wrap: wrap;
  /* margin: 0% 13%; */
}
.case-content-row{
  /* margin-bottom: 15px; */
  width: 33%;

}
.case-size{
  width: 360px;
  height: 241px;
  /* margin: 0px 30px; */
  object-fit: cover;

}
.case-item-title{
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 37.65px;
  color: rgba(0, 0, 0, 1);
  /* margin-left: 29px; */
}
.case-item-content{
  width: 360px;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 25px;
  color: rgba(48, 48, 48, 1);
  /* margin-left: 29px; */
}
.case-bottom{
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 46.34px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
</style>