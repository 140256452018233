import { createApp } from 'vue'
// import App from './App.vue'
import WebPages from './pages/WebPages.vue'

// import IndexBox from './pages/IndexBox.vue'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import Carousel3d from 'vue3-carousel-3d'
import 'vue3-carousel-3d/dist/index.css'
import router from './Router/vueRouter'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import axios from './axios'

const app = createApp(WebPages)
// const app = createApp(CircleCircel)
// const app = createApp(IndexBox)
// const app = createApp(BirdLock)
// const app = createApp(BirdLock1)
// const app = createApp(Circle)
// const app = createApp(birds)
app.use(ElementPlus)
app.use(Carousel3d)
app.use(VueVideoPlayer)
app.use(router)
app.config.globalProperties.$axios=axios;
// app.use(VideoPlayer)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.mount('#app')
