<template>
  <div>

    <div class="content-box">
      <div class="content-title">
        <div class="content-title-big">软件开发</div>
        <div class="content-title-small">软件开发定制，只为您的独特需求而生</div>
      </div>
      <hr>
      <div class="content-frame">
        <div class="content-item">定制软件开发，一诺千金</div>
        <template v-for="item in list" :key="item">
          <div class="dot"></div>
          <div class="content-dot">{{ item }}</div>
        </template>
      </div>
      <button class="btn-price" @click="dialogVisible = true">获取开发流程</button>
      <el-dialog v-model="dialogVisible" width="1000">
        <img src="../static/image/process.png" class="process">
      </el-dialog>

      <div class="search-price">
        <div class="text-item">已有<span style="color: red;">{{ number }}人</span>在获取报价</div>
        <div class="frame-content">
          <div class="search-price-frame">
            <div class="select">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  {{ currentItem }}<el-icon class="el-icon-right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="item in design"
                      :key="item.id"
                      @click="select(item)">
                      {{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="line"></div>
            <input class="input-number" v-model="mobile" placeholder="请输入手机号">
            <div class="line"></div>
            <!-- <div class="get-code">点击获取验证码</div>
            <div class="line"></div>
            <input class="input-code" placeholder="请输入验证码">
            <div class="line"></div> -->
            <input class="input-number" v-model="price" placeholder="请输入预算">

          </div>
          <button class="btn-search" @click="addbj">查看报价</button>
        </div>
        <div class="text-item">你可能还需要：软件维护 部署 UI设计 外包服务</div>
      </div>

      <div class="video">
        <el-icon class="el-icon-video"><VideoPlay /></el-icon>
        <div class="text-item">了解更多有关我们的资料</div>
      </div>
    </div>
    <!-- <ContentCenter></ContentCenter> -->
  </div>
</template>
<script>
// import ContentCenter from './contentCenter.vue';
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components:{
    // ContentCenter
  },
  data(){
    return{
      number:15500,
      list:["小程序定制","物联网","APP定制开发"],
      design:[],
      currentItem:"请选择",
      name: '',
      type:'',//开发类型
      mobile:'',
      price:'',//开发预算
      dialogVisible: false,
    }
  },


  created() {
    this.s()
    this.getCustomerRequirementAllCount()
  },


  methods:{
    getCustomerRequirementAllCount() {
      this.$axios.get('/customer.customerRequirement/allCount',{
      }).then((res)=>{
        this.number = res.data.data.number;
      })
    },
    //项目类别全查
    s(){
      this.$axios.get('/index/dict',{
        params : {
          type: 'development_type'
        }
      }).then((res)=>{
        // this.design=res.data
        this.design=res.data.data.development_type
        console.log(res.data)
      })
    },

    //项目类别的下拉框
    select(item) {
      this.type = item.value
      // 更新显示的当前项
      this.currentItem = item.name;
    },

    //添加项目报价
    addbj() {
      if(this.mobile === '') {
        this.$message.error("手机号不能为空");
        return
      }
      this.$axios.post('/customer.customerRequirement/add', {
        name: this.name,
        type: this.type,
        price: this.price,
        mobile: this.mobile
      }).then((res) => {
        if (res.data.code === 1) {
          ElMessageBox.alert('已经了解你的需求，稍后会有专业的人联系您！！！！', '报价', {
            confirmButtonText: 'OK',
          });
          this.mobile='';
          this.getCustomerRequirementAllCount()
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch((error) => {
        console.error("请求失败", error);
      });
    },


  },
}
</script>
<style scoped>
.content-box{
  width: fit-content;
  padding-left: 110px;
  position: relative;
  z-index: 1;
}
.content-title{
  display: flex;
  margin-top: 120px;
}
.content-title-big{
  font-size: 80px;
  font-weight: 500;
  margin-right: 10px;
  background: linear-gradient(to right,rgba(77, 0, 255, 1),rgba(115, 224, 169, 1),rgba(115, 224, 169, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content-title-small{
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 41.16px;
  margin-top: 50px;
  background: linear-gradient(to right,rgba(92, 196, 189, 1)30%,rgba(119, 76, 233, 1),rgba(119, 76, 233, 1)100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content-frame{
  display: flex;
  align-items: center;
}

.content-item{
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 41.16px;
  color: rgba(140, 114, 243, 1);
}
.frame-content{
  display: flex;
}
hr{
  border: 2px solid rgba(121, 72, 234, 1);
}
.dot{
  width: 11px;
  height: 11px;
  opacity: 1;
  background: rgba(92, 196, 189, 1);
  border-radius: 100%;
  margin-left: 50px;
  margin-right: 10px;
}
.content-dot{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(140, 114, 243, 1);
}
.btn-price{
  width: 170px;
  height: 40px;
  border-radius: 90px;
  color: white;
  border: none;
  margin-top: 20px;
  background: linear-gradient(135deg, rgba(115, 224, 169, 1) 0%, rgba(91, 104, 223, 1) 100%);
}
.process{
  width: 950px;
  height: 700px;
}
.search-price{
  margin-top: 90px;

}
.search-price-frame{
  width: 750px;
  display: flex;
  align-items: center;
  background: white;
  padding: 6px 0px 6px 20px;
  margin: 5px 0px;
  border-radius: 3px;
}
.select{
  width: 210px;
}
.el-dropdown-link{
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(74, 74, 74, 1);
  display: flex;
  align-items: center;
}
.el-icon-right{
  width: 14px;
  margin: 0 5px;
}
.el-dropdown{
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 50px; */
}
.input-number{
  width: 210px;
  border: none;
  outline: none;
  margin-left: 10px;
  font-size: 17px;
}
input::placeholder {

  text-align:center
}
.line{
  height: 20px;
  margin:0px 15px;
  border-left: 1px solid rgba(71, 71, 71, 1);
}
/* .get-code{
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(74, 74, 74, 1);
}
.input-code{
  width: 15%;
  border: none;
  outline: none;
  font-size: 17px;
} */
.btn-search{
  width: 100px;
  background: rgba(0, 141, 239, 1);
  border: none;
  outline: none;
  margin: 5px 0px;
  color: white;
  border-radius: 3px;
}
.text-item{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(31, 29, 43, 1);
}
.el-icon-video{
  color: rgb(26, 156, 216);
  display: flex;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.video{
  display: flex;
  margin: 80px 0px;
}
</style>