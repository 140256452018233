<template>
  <div class="content-app">
    <div class="frame-company">
      <div class="company-title">公司简介</div>
      <div class="company-item">我们提供个性化服务，为各行各业提供优质服务</div>

      <div class="company-text">{{gjj}}</div>


      <div class="company-img">
        <template v-for="item in images" :key="item.id">
          <img :src="item.image" class="image-size">
        </template>

      </div>


    </div>
    <div class="company-progress">
      <div class="company-title">发展历程</div>
      <div class="company-item">我们提供个性化服务，为各行各业提供优质服务</div>
      <div class="time-line">
        <el-timeline style="max-width: 600px">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="'primary'"
            :color="'rgba(94, 117, 217, 1)'"
            :size="'normal'"
            :hollow="activity.hollow"
            :timestamp="activity.date"
          >
            {{ activity.event }}
          </el-timeline-item>
        </el-timeline>
      </div>

    </div>
    <div class="frame-company">
      <div class="company-title">合作伙伴</div>
      <div class="company-item">我们提供个性化服务，为各行各业提供优质服务</div>
      <div class="frame-logo">
        <template v-for="item in logos" :key="item">
          <img :src="item" class="logo-size">
        </template>
      </div>
    </div>
    <ContentBottom></ContentBottom>
  </div>
</template>

<script>
import ContentBottom from './contentBottom.vue';
import {getCompleteImageUrl} from '../imgurl.js'
export default{
  components:{
    ContentBottom
    // timeLine
  },
  data(){
    return{
      gjj:'',
      images:[],
      logos:[
        require("@/static/logos/logo1.png"),
        require("@/static/logos/logo2.png"),
        require("@/static/logos/logo3.png"),
        require("@/static/logos/logo4.png"),
        require("@/static/logos/logo5.png"),
        require("@/static/logos/logo6.png"),
      ],
      activities:[]
    }
  },

  created() {
    this.gsjj()
    this.gstp()
    this.gslc()
  },
  methods:{
    //查询公司简介
    gsjj(){
      // this.$axios.get('/companyprofile/getAll').then((res) => {
      //   console.log(res.data)
      //   this.gjj = res.data[0].intro
      // })

      this.$axios.get('/index/getWebsite',{
      }).then((res) => {
        console.log(res.data)
        this.gjj = res.data.data.company_description
      }).catch((error)=>{
        console.error('Error fetching data:',error)
      })
    },

    //查询公司图片
    gstp(){
      // this.$axios.get('/companyimg/getAll').then((res) => {
      this.$axios.get('/company.companyImg/getAll').then((res) => {
        // this.images=res.data.map(item=>({...item,image:getCompleteImageUrl(item.image)}))
        this.images = res.data.data
      }).catch((error)=>{
        console.error('Error fetching data:',error);
      });
    },

    //公司发展历程
    gslc(){
      // this.$axios.get('/developcourse/getAll').then((res)=>{
      this.$axios.get('/company.companyHistory/getAll').then((res)=>{
        this.activities=res.data.data
      }).catch((error)=>{
        console.error('Error fetching data:',error);
      });
    },












  },






}
</script>

<style scoped>
.content-app{

  background: radial-gradient(60% 50% at 80% 50%,rgb(176, 167, 234),transparent),
  radial-gradient(100% 40% at left 40%,rgb(160, 228, 193),transparent),
  radial-gradient(60% 50% at left 60%,rgb(176, 167, 234),transparent),
  radial-gradient(80% 50% at right bottom,rgb(160, 228, 193),transparent);
  /* background: linear-gradient(
      7deg,
      rgba(115, 224, 169, 1),
      rgba(255, 0, 0, 0) 60.71%
    ),
    linear-gradient(344deg, rgba(77, 0, 255, 1), rgba(0, 0, 255, 0) 50.71%); */
}
.frame-company{
  padding: 30px 80px;
  /* height: 470px; */
  background: rgba(255, 255, 255, 1);
  margin-bottom: 30px
}
.company-title{
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 49.39px;
  color: rgba(31, 29, 43, 1);
  text-align: center;
}
.company-item{
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.58px;
  color: rgba(56, 56, 56, 1);
  text-align: center;
  margin: 10px 0px;
}
.company-img{
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.image-size{
  /* width: 370px; */
  width: 30%;
  /* height: 270px; */
  border-radius: 8px;
  margin-right: 2%;
  /* object-fit: cover; */
}
.company-progress{

}
.time-line{
  /* margin-left: 200px; */
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 50px;
}
.el-timeline-item__tail{
  border-left: 4px solid rgba(113, 215, 173, 1)
}
.frame-logo{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* width: 35%; */
}
.logo-size{
  /* width: 355px; */
  width: 30%;
  /* height: 112px; */
  margin: 1.5%;
  /* margin-bottom: 24px */

}
</style>