<template>

  <div id="login" ref="Area">

  </div>

</template>

<script setup>

import { onMounted,onBeforeUnmount,ref } from "vue";

//导入vanta.js和three.js，以及ref等hooks

import * as THREE from 'three';

import BIRDS  from '@/dist/vanta.birds.min.js';


 

//使用ref引用挂载区域

const Area=ref(null)

//创建一个全局的变量来使用vanta.js

/**

  *因为在vue2中，是使用this.vantaEffect来创建指定的3d动画模板的

  *但是vue3 setup中是没有this，所以要另外创建一个

**/

let vantaEffect=null;

//在两个生命周期钩子内创建vantaEffect

onMounted(()=>{

    vantaEffect=BIRDS ({

        el:Area.value,

        THREE:THREE,

        //如果需要改变样式，要写在这里

        //因为这里vantaEffect是没有setOptions这个方法的

        // color: 0x16212a,

        mouseControls: true,

      touchControls: true,

      gyroControls: true,

      scale: 1.0,

      color1: 0x186307,

      color2: 0xd000ff,

      backgroundColor: 0xFFFFFF,

      backgroundAlpha:0,

      wingSpan: 30,

    })

})

onBeforeUnmount(()=>{

    if(vantaEffect){

        vantaEffect.destroy()

    }

})

</script>

<style lang="scss" scoped>

#login {

  height: 100vh;

  // background-image: url("../assets//03.jpg");

  background-repeat: no-repeat;

  background-size: cover;

  background-position: center center;

  display: flex;

  justify-content: center;

  align-items: center;

  perspective: 800px;

  background: transparent;

}

</style>