<template>
  <div>

    <div class="content-box">
      <div class="content-title">
        <div class="content-title-big">案例精选</div>
        <!-- <div class="content-title-small">软件开发定制，只为您的独特需求而生</div> -->
      </div>
      <hr>
      <div class="content-frame">
        <div class="content-item">我们将百分百用心地对待每一个产品</div>
        <!-- <template v-for="item in list" :key="item">
          <div class="dot"></div>
          <div class="content-dot">{{ item }}</div>
        </template> -->
      </div>
      <button class="btn-price" @click="dialogVisible = true">获取报价</button>
      <el-dialog v-model="dialogVisible" width="500">
        <img src="../static/image/get-bg.png" class="get-bg">
        <div class="form">
          <el-form :model="form" label-width="auto" style="max-width: 600px">
            <el-form-item label="姓名:">
              <el-input placeholder="请输入您的称呼方式" v-model="form.name" />
            </el-form-item>
            <el-form-item label="联系电话:">
              <el-input placeholder="请输入您的联系电话" v-model="form.mobile" />
            </el-form-item>
            <el-form-item label="开发需求:">
              <el-select placeholder="" v-model="form.type">
                <!-- <el-option label="Zone one" value="shanghai" />
                <el-option label="Zone two" value="beijing" /> -->
                <el-option
                  v-for="item in design"
                  :key="item.id"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="预算:">
              <el-input placeholder="请输入您的预算" v-model="form.price" />
            </el-form-item>
          </el-form>
          <div class="submit">
            <el-button @click="addbj">提交</el-button>
          </div>
        </div>
      </el-dialog>


    </div>
    <!-- <ContentCenter></ContentCenter> -->
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components:{
    // ContentCenter
  },
  data(){
    return{
      number:15500,
      list:["小程序定制","物联网","APP定制开发"],
      design:[],
      currentItem:"请选择",
      currentPrice:"请选择",
      price:[],
      dialogVisible: false,
      form: {
        name: '',
        mobile: '',
        type: '',
        price: ''
      },
      xmlb:'',
      xmys:'',

    }
  },
  created() {
    this.s()
  },
  methods:{
    //项目类别全查
    s(){
      this.$axios.get('/index/dict',{
        params : {
          type: 'development_type'
        }
      }).then((res)=>{
        this.design=res.data.data.development_type
        console.log(res.data)
      })
    },
    //项目类别的下拉框
    select(item) {
      console.log('Selected item:', item);  // 打印整个 item 对象
      console.log('Selected item ID:', item.id);  // 打印 id 属性
      this.xmlb=item.id
      // 更新显示的当前项
      this.currentItem = item.xm;
    },

    //添加项目报价
    addbj() {
      this.$axios.post('/customer.customerRequirement/add', {
        name: this.form.name,
        type: this.form.type,
        price: this.form.price,
        mobile: this.form.mobile
      }).then((res) => {
        if (res.data.code === 1) {
          ElMessageBox.alert('已经了解你的需求，稍后会有专业的人联系您！！！！', '报价', {
            confirmButtonText: 'OK',
          });
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch((error) => {
        console.error("请求失败", error);
      });
    },
  },
}
</script>
<style scoped>
.content-box{
  width: fit-content;
  height: 530px;
  padding-left: 110px;
  position: relative;
  z-index: 1;
}
.content-title{
  display: flex;
  margin-top: 120px;
}
.content-title-big{
  font-size: 80px;
  font-weight: 500;
  margin-right: 10px;
  background: linear-gradient(to right,rgba(77, 0, 255, 1),rgba(115, 224, 169, 1),rgba(115, 224, 169, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content-title-small{
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 41.16px;
  margin-top: 50px;
  background: linear-gradient(to right,rgba(92, 196, 189, 1)30%,rgba(119, 76, 233, 1),rgba(119, 76, 233, 1)100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content-frame{
  display: flex;
  align-items: center;
}

.content-item{
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 41.16px;
  color: rgba(140, 114, 243, 1);
}
.frame-content{
  display: flex;
}
hr{
  border: 2px solid rgba(121, 72, 234, 1);
  width: 480px;
  margin-left: 0px;
}
.dot{
  width: 11px;
  height: 11px;
  opacity: 1;
  background: rgba(92, 196, 189, 1);
  border-radius: 100%;
  margin-left: 50px;
  margin-right: 10px;
}
.content-dot{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(140, 114, 243, 1);
}
.btn-price{
  width: 170px;
  height: 40px;
  border-radius: 90px;
  color: white;
  border: none;
  /* margin-top: 20px; */
  margin-top: 120px;
  background: linear-gradient(135deg, rgba(115, 224, 169, 1) 0%, rgba(91, 104, 223, 1) 100%);
}
:deep(.el-dialog){
  --el-dialog-padding-primary:0
}
.get-bg{
  width: 100%;
}
.form{
  padding: 20px;
}
.submit{
  display: flex;
  justify-content: center;
}
:deep(.el-button){
  width: 100px;
  color: #fff;
  background: linear-gradient(to right, rgb(141, 158, 241), rgb(56, 130, 233));
}
.search-price{
  margin-top: 90px;

}
.search-price-frame{
  width: 750px;
  display: flex;
  align-items: center;
  background: white;
  padding: 6px 0px 6px 20px;
  margin: 5px 0px;
  border-radius: 3px;
}
.el-dropdown-link{

  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(74, 74, 74, 1);
  display: flex;
  align-items: center;
}
.el-icon-right{
  width: 14px;
}
.el-dropdown{
  width: 180px;
  margin-left: 50px;
}
.input-number{
  width: 180px;
  border: none;
  outline: none;
  margin-left: 10px;
  font-size: 17px;
}
input::placeholder {

  text-align:center
}
.line{
  height: 20px;
  margin:0px 15px;
  border-left: 1px solid rgba(71, 71, 71, 1);
}
/* .get-code{
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 27.44px;
  color: rgba(74, 74, 74, 1);
}
.input-code{
  width: 15%;
  border: none;
  outline: none;
  font-size: 17px;
} */
.btn-search{
  width: 100px;
  background: rgba(0, 141, 239, 1);
  border: none;
  outline: none;
  margin: 5px 0px;
  color: white;
  border-radius: 3px;
}
.text-item{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(31, 29, 43, 1);
}
.el-icon-video{
  color: rgb(26, 156, 216);
  display: flex;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.video{
  display: flex;
  margin-top: 50px;
}
</style>