<template>
    <div>
      <div class="text-login" style="position: fixed;right: 10px;top: 45%;">
            <el-popover
              placement="bottom"
              :width="165"
              trigger="click"
            >
            <div>
              <span class="number">189 5515 1876</span>
              <span class="texta" @click="copyNumber">复制</span>
            </div>
              <template #reference>
                <div class="get-number">
                  <el-button class="m-2">
                    <img src="@/static/icon/number.png" class="icon-number">
                    获取手机号码
                    </el-button>
                </div>
              </template>
            </el-popover>
          </div>
          
          <div class="text-login" style="position: fixed;right: 10px;top: 55%;">
            <el-popover
              placement="bottom"
              :width="150"
              trigger="click"
            >
            <div class="add">
              <img src="@/static/image/image.png">
              <div class="text"><span class="item">微信扫一扫 &nbsp; 免费咨询</span></div>
            </div>
              <template #reference>
                <el-button class="m-2">
                  <img src="@/static/icon/weixin.png" class="icon-weixin">
                  添加企业微信
                  </el-button>
              </template>
            </el-popover>
            <!-- <span>登录</span> -->
          </div>

        <div class="frame">
            <div class="CS">
              <el-badge :value="unread" :hidden="unread === 0" class="item">
                <div @click="dialogTableVisible = true">
                  <img src="@/static/CS/CS.png" class="CS-avator">
                  <div class="link">联系客服</div>
                </div>
              </el-badge>
                <el-dialog
                 v-model="dialogTableVisible" 
                 :show-close="false"
                  @click="dialogOverflowVisible = true"
                   draggable>
                    <template #header> 
                        <div class="chatBox-title">
                        <div class="avator">
                            <img src="../static/logos/anhuiyulianshujuyouxiangongsi02.png" class="avator-size">
                        </div>
                        <div class="title">羽链数据-官方咨询</div>
                        <div class="icon" @click="dialogTableVisible = false">
                            <img src="@/static/icon/close.png" class="img">
                        </div>
                    </div>
                    </template>
                    <IndexBox :dialogTableVisible="dialogTableVisible" @addUnread="addUnRead()" @clearUnread="clearUnread()"></IndexBox>
                </el-dialog>
                <!-- <div class="line"></div>
                <img src="@/static/CS/price.png" class="get-price">
                <div class="link">获取报价</div> -->
            </div>
        </div>
    </div>
</template>
<script>
import IndexBox from '@/pages/IndexBox.vue';

export default {
    components:{
        IndexBox
    },
    data(){
        return{
            dialogTableVisible:false,
            unread:0,
            // popupVisible: false,
        }
    },
    methods:{
      async copyNumber() {
        const number = '189 5515 1876';
        try {
          await navigator.clipboard.writeText(number);
          this.$message.success('号码已复制到剪贴板');
        } catch (err) {
          this.$message.error('复制失败');
        }
      },
        showPopup(){
            this.popupVisible = true;
        },
      addUnRead(){
          this.unread += 1;
      },
      clearUnread(){
          this.unread = 0;
      }
    }
}
</script>
<style scoped lang="less">
.text-login{
  z-index: 1;
  margin: 0 10px;
   :deep(.el-button){
    border-radius: 25px;
    background: linear-gradient(135deg, rgba(115, 224, 169, 1) 0%, rgba(91, 104, 223, 1) 100%);
    border: none;
    color: #fff;
   }

  }
  .number{
    margin-right: 10px;
  }
  .texta{
    color: rgba(92, 111, 220, 1);
    cursor: pointer;
  }
  .icon-number{
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .add{

    img{
      width: 120px;
      height: 120px;
    }
    .text{
      display: flex;
      justify-content: center;
       .item{
        width: 90px;
        text-align: center;
       }
    }
  }
  .icon-weixin{
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
.frame{
    /* width: 5%; */
    width: 86px;
    height: 86px;
    opacity: 1;
    border-radius: 84px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    position: fixed;
    right: 50px;
    top: 30%;
    z-index: 2;
}
.CS{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CS-avator{
    width: 50px;
    height: 40px;
    margin-top: 10px;
}
.link{
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(94, 117, 217, 1);
    text-align: center;
}
.line{
    width: 80%;
    height: 1px;
    margin: 5px 0px;
    border-bottom: 2px solid rgba(214, 212, 212, 1);
}
.get-price{
    width: 48px;
    height: 40px;
}


:deep(.el-dialog){
    border-radius: 20px;
    padding: 0px;
    width: 480px;
    position: fixed;
    right: 0px;
    bottom: 0px;
    margin: 0;
}
:deep(.el-overlay) {
  background-color: transparent; /* 设置遮罩层背景色为透明 */
}
.chatBox-title{
    display: flex;
    height: 60px;
    background: linear-gradient(to right,rgb(178, 150, 245),rgb(134, 229, 182));
    border-radius: 18px 18px 0px 0px;
    align-items: center;
  }
  .avator{
    width: 45px;
    height: 45px;
    opacity: 1;
    border-radius: 26px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }
  .avator-size{
    width: 80%;
    height: 80%;
  }
  .title{
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 31.86px;
    color: rgba(255, 255, 255, 1);
    margin-left: 15px
  }
  .icon{

  }
  .img{
    width: 44px;
    height: 44px;
    position: absolute;
    right: 15px;
    top: 10px;
  }
</style>
<style>
.item .el-badge__content {
  font-size: 20px; /* 设置小圆点中数字的字号 */
  padding: 6px 6px; /* 设置小圆点的内边距 */
}
</style>