// src/axios.js
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8088/',
  // baseURL: 'http://www.yulianshuju.com/api',
  baseURL: 'https://www.yulianshuju.com/api',
  // 其他配置项
});

export default instance;
