
import contentTop from '../components/contentTop.vue'
import {createRouter,createWebHashHistory} from 'vue-router'
import ContentCenter from '@/components/contentCenter.vue'
import CaseTop from '@/components/CaseTop.vue'
import AboutTop from '@/components/AboutTop.vue'
import AboutCenter from '@/components/AboutCenter'
import CaseContent from '@/components/CaseContent.vue'
// import WebCase from '@/pages/WebCase.vue'
import CaseKind from '@/components/CaseKind.vue'
// import CaseKind from '@/components/CaseKind.vue'

const routes = [
    
    {
        path:'/',
        redirect:'/Index',
        children:[
            {
                path:'/Index',
                components:{
                    Top:contentTop,
                    Bottom:ContentCenter,
                },
            },
            {
                path:'/WebCase',
                components:{
                    Top:CaseTop,
                    Bottom:CaseKind
                }
            },
            {
                path:'/Case',
                components:{
                    Top:CaseTop,
                    Bottom:CaseContent,
                }
            },
            {
                path:'/About',
                components:{
                    Top:AboutTop,
                    Bottom:AboutCenter,
                }
            },
        ]
    }
]

const router = createRouter ({
    routes,
    history : createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth'
          };
        } else if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      }
})
router.beforeEach((to) => {
    if (to.path === '/ase') {
        // 如果用户导航到 '/custom-path'，则滚动到特定位置
        window.scrollTo(0, 700); // 例如，距离顶部200像素
      } else {
        // 对于其他路由，滚动到页面顶部
        window.scrollTo(0, 0);
      }
  });
export default router