// urlHelper.js  
  
// 定义基础URL常量  
// const BASE_URL = 'http://localhost:8088/uploads/';  
const BASE_URL = 'http://www.yulianshuju.com/api/uploads/';  

  
// 导出函数，该函数使用基础URL来构建完整的图片URL  
export function getCompleteImageUrl(filePath) {  
  return BASE_URL + filePath;  
}